import React, { useState } from "react";
import PortableText from "react-portable-text";
import Accordion from "./Accordion";

const Faqs = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChangeQuestion = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="relative block box-border pb-10 show">
      <div className="relative max-w-[1140px] w-full mx-auto">
        <div className="flex flex-col flex-wrap justify-center p-[10px] sm:p-5">
          {/* Heading */}
          <div className="block w-3/4 mx-auto py-5">
            <h1 className="text-[26px] sm:text-3xl text-assent font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
              {data.heading}
            </h1>
          </div>
          {/* Buttons */}
          <div className="block w-full mx-auto">
            {data.faqList?.map((item, index) => (
              <Accordion
                id={index}
                question={item.question}
                rawAnswer={item._rawAnswer}
                key={index}
                selectedItem={selectedIndex}
                setSelectedItem={setSelectedIndex}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
