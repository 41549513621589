import React from "react";
import PortableText from "react-portable-text";

const Accordion = ({
  question,
  rawAnswer,
  id,
  selectedItem,
  setSelectedItem,
}) => {
  const serializers = {
    types: {
      link: ({ node }) => {
        return <a href={`/faq/${node.slug.current}`}>{node}</a>;
      },
      code: (props) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  };
  function handleChange() {
    if (selectedItem === id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(id);
    }
  }
  return (
    <div className="relative flex flex-col break-words bg-clip-border bg-transparent border-none rounded-none mb-[15px] shadow-lg shadow-primary/15 transition-shadow">
      <div className="px-5 mb-0 bg-transparent border-b-0 rounded-tl-custom rounded-tr-custom break-words">
        <h3
          className={`text-xl pr-10 mb-2 sm:pr-0 
          relative w-full block py-3 transition-all duration-200 ease-linear border-b border-solid border-black/20 text-[#212121] cursor-pointer accordion ${
            selectedItem === id
              ? "after:rotate-90 font-medium "
              : "after:rotate-0 font-medium"
          }`}
          onClick={handleChange}
        >
          {question}
        </h3>
        <div
          className={`${
            selectedItem === id ? "max-h-96" : "max-h-0"
          } break-words transform transition-all duration-300 ease-linear overflow-hidden h-full`}
        >
          <div className="py-3 px-1 grow shrink basis-auto font-dinnext text-black text-lg">
            <PortableText
              // Pass in block content straight from Sanity.io
              content={rawAnswer}
              // Optionally override marks, decorators, blocks, etc. in a flat
              // structure without doing any gymnastics
              serializers={{
                normal: ({ children }) => (
                  <p className="leading-7  mb-4">{children}</p>
                ),
                link: ({ children, href }) => (
                  <a href={href} className="text-[#2a5ddf] underline">
                    {children}
                  </a>
                ),
                ol: ({ children }) => (
                  <ol className="list-decimal list-outside">{children}</ol>
                ),
                ul: ({ children }) => (
                  <ul className="list-disc list-outside">{children}</ul>
                ),
                div: (props) => (
                  <div className="mb-[50px] leading-[200px]" {...props} />
                ),
                li: ({ children }) => (
                  <li className="special-list-item mb-9">{children}</li>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
